html,body{
  height: 100%;
  width: 100%;
}
*{
  box-sizing: border-box;
  font-family: "Inter", sans-serif!important;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.app-container {
  padding: 20px; /* Adjust the padding as needed */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}


.mobile-view{
  display: none;
}
@media only screen and (max-width: 600px) {
  .desktop-view{
    display: none;
  }
  .mobile-view{
    display: block;
  }
  
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .desktop-view, .mobile-view {
    display: none; /* Hide desktop and mobile views on iPad screens */
  }
  .mobile-view {
    display: block; /* Show iPad view */
  }
}
.audit-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}
.custom-cursor {
  cursor: not-allowed !important; /* Change cursor to not-allowed */
}
.filter-accordion {
  width: 16%; 
  margin-bottom: 10px; 
}

.filter-options {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.swal2-container{
  z-index: 1301;
}

.select-box select {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  width: 150px; /* Adjust the width as needed */
}


/* table */

.accordion {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ccc;
}

.accordion-header:hover {
  background-color: #f1f1f1;
}

.month-title {
  font-size: 16px;
  font-weight: bold;
  color: #17428b;
}

.export-btn {
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
}

.accordion-content {
  padding: 10px;
  border-top: 1px solid #ccc;
}

.accordion-group {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.accordion-summary {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f1f1f1;
  cursor: pointer;
}

.accordion-summary:hover {
  background-color: #e9e9e9;
}

.group-title {
  font-size: 14px;
  font-weight: bold;
  color: #17428b;
}

.average-scores {
  display: flex;
  gap: 10px;
}

.accordion-details {
  padding: 10px;
  background-color: #fafafa;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.custom-table th, .custom-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.custom-table th {
  background-color: #f1f1f1;
  font-weight: bold;
}

.custom-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table tr:hover {
  background-color: #e9e9e9;
}
.MuiAccordionSummary-content{
  margin: 0!important;
  display: block!important;
  padding: 0!important;
}

